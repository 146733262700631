<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('Data Interface') }}</div>
            </div>
            <template>
                <div class="Info">
                    <el-form :model="formData" ref="from" :rules="formData.fromaDataRules" label-position="top" v-bind:show-message="notip">
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('InterfaceName')" placement="top-start">
                                    <el-form-item :label="$t('InterfaceName')" prop="interfaceName" label-width="120px">
                                        <el-input v-model.trim="formData.interfaceName" style="width: calc(100% - 115px);" maxlength="100" v-bind:disabled="diasabledInput" readonly="true" clearable></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('RequestPo')" placement="top-start">
                                    <el-form-item :label="$t('RequestPo')" prop="requestPo" label-width="120px">
                                        <el-input v-model.trim="formData.requestPo" style="width: calc(100% - 115px);" maxlength="64" v-bind:disabled="diasabledInput"  readonly="true" clearable></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('RequestPoTracingNo')" placement="top-start">
                                    <el-form-item :label="$t('RequestPoTracingNo')" prop="requestPoTracingNo" label-width="120px">
                                        <el-input v-model.trim="formData.requestPoTracingNo" style="width: calc(100% - 115px);" maxlength="100" v-bind:disabled="diasabledInput"  readonly="true" clearable></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('RequestSo')" placement="top-start">
                                    <el-form-item :label="$t('RequestSo')" prop="requestSo" label-width="120px">
                                        <el-input v-model.trim="formData.requestSo" style="width: calc(100% - 115px);" maxlength="64" v-bind:disabled="diasabledInput"  readonly="true" clearable></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('RequestSoTracingNo')" placement="top-start">
                                    <el-form-item :label="$t('RequestSoTracingNo')" prop="requestSoTracingNo" label-width="120px">
                                        <el-input v-model.trim="formData.requestSoTracingNo" style="width: calc(100% - 115px);" maxlength="100" v-bind:disabled="diasabledInput"  readonly="true" clearable></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('ProcessCode')" placement="top-start">
                                    <el-form-item :label="$t('ProcessCode')" prop="processCode" label-width="120px">
                                        <el-input v-model.trim="formData.processCode" style="width: calc(100% - 115px);" maxlength="64" v-bind:disabled="diasabledInput"  readonly="true" clearable></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('FieldValue')" placement="top-start">
                                    <el-form-item :label="$t('FieldValue')" prop="fieldValue" label-width="120px">
                                        <el-input v-model.trim="formData.fieldValue" style="width: calc(100% - 115px);" maxlength="4096" v-bind:disabled="diasabledInput"  readonly="true" clearable></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('FileValue')" placement="top-start">
                                    <el-form-item :label="$t('FileValue')" prop="fileValue" label-width="120px">
                                        <el-input v-model.trim="formData.fileValue" style="width: calc(100% - 115px);" maxlength="4096" v-bind:disabled="diasabledInput"  readonly="true" clearable></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('UsedNumber')" placement="top-start">
                                    <el-form-item :label="$t('UsedNumber')" prop="usedNumber" label-width="120px">
                                        <el-input v-model.trim="formData.usedNumber" style="width: calc(100% - 115px);" maxlength="100" v-bind:disabled="diasabledInput"  readonly="true" clearable></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('LOSSRATE')" placement="top-start">
                                    <el-form-item :label="$t('LOSSRATE')" prop="lossRate" label-width="120px">
                                        <el-input v-model.trim="formData.lossRate" style="width: calc(100% - 115px);" maxlength="64" v-bind:disabled="diasabledInput"  readonly="true" clearable></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('FIBER TYPE')" placement="top-start">
                                    <el-form-item :label="$t('FIBER TYPE')" prop="fiberType" label-width="120px">
                                        <el-input v-model.trim="formData.fiberType" style="width: calc(100% - 115px);" maxlength="100" v-bind:disabled="diasabledInput"  readonly="true" clearable></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('RATIO')" placement="top-start">
                                    <el-form-item :label="$t('RATIO')" prop="ratio" label-width="120px">
                                        <el-input v-model.trim="formData.ratio" style="width: calc(100% - 115px);" maxlength="64" v-bind:disabled="diasabledInput"  readonly="true" clearable></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </template>
        </basic-container>
    </div>
</template>

<script>
    import {
        GetListDataType,
        GetListDataLogInfosByType,
        GetDataLogInfos,
        GetDataLogInfo,
        GetDataInterfaceByDataLog
    } from '@/api/datalog'
    export default {
        name: 'dataInterface',
        data() {
            return {
                formData: {
                    loadId: '',
                    interfaceName: '', requestPo: '', requestPoTracingNo: '', requestSo: '', requestSoTracingNo: '', processCode: '', fieldValue: '',
                    fileValue: '', usedNumber: '', lossRate: '', fiberType: '', ratio: '',

                }
            }
        },
        created() {
            this.getListInfo();
            },
        methods: {//��������
            init() {
                this.formData.loadId = this.$route.query.ID;           
                this.getListInfo();                
            }, 
            getListInfo() {
                GetDataInterfaceByDataLog(this.$route.query.ID).then(res => {
                    var infodata = res.data;
                    this.formData.interfaceName = infodata.interfaceName;
                    this.formData.requestPo = infodata.requestPo;
                    this.formData.requestPoTracingNo = infodata.requestPoTracingNo;
                    this.formData.requestSo = infodata.requestSo;
                    this.formData.requestSoTracingNo = infodata.requestSoTracingNo;
                    this.formData.processCode = infodata.processCode;

                    this.formData.fieldValue = infodata.fieldValue;
                    this.formData.fileValue = infodata.fileValue;
                    this.formData.usedNumber = infodata.usedNumber;
                    this.formData.lossRate = infodata.lossRate;
                    this.formData.fiberType = infodata.fiberType;
                    this.formData.ratio = infodata.ratio;
                }).catch((erro) => { console.log(erro) });
            },
        }
    }
</script>


<style>
    .notifyStyle {
        width: 30% !important;
        background: #FFE4E1;
    }

    table th.star div::before {
        content: ' * ';
        color: red;
    }

    .ttt {
        width: 100px;
    }

    .searlable .el-checkbox__label {
        color: #8CA0B3;
        font-weight: 500;
    }

    .smallTit {
        margin: 1.3rem 0 1.25rem;
    }

    .formD {
        padding: 0 40px;
    }

    .el-row_style {
        display: flex;
        flex-wrap: wrap;
        height: 90px;
    }

    .el-col_style {
        height: 45px;
    }

    .el-form--label-top .el-form-item__label {
        float: none;
        /*  display: inline-block;*/
        text-align: left;
        padding: 0 0 0px;
    }
</style>