var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [_vm._v(_vm._s(_vm.$t("Data Interface")))]
            )
          ]),
          [
            _c(
              "div",
              { staticClass: "Info" },
              [
                _c(
                  "el-form",
                  {
                    ref: "from",
                    attrs: {
                      model: _vm.formData,
                      rules: _vm.formData.fromaDataRules,
                      "label-position": "top",
                      "show-message": _vm.notip
                    }
                  },
                  [
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("InterfaceName"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("InterfaceName"),
                                      prop: "interfaceName",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        maxlength: "100",
                                        disabled: _vm.diasabledInput,
                                        readonly: "true",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.formData.interfaceName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "interfaceName",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formData.interfaceName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("RequestPo"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("RequestPo"),
                                      prop: "requestPo",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        maxlength: "64",
                                        disabled: _vm.diasabledInput,
                                        readonly: "true",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.formData.requestPo,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "requestPo",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formData.requestPo"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("RequestPoTracingNo"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("RequestPoTracingNo"),
                                      prop: "requestPoTracingNo",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        maxlength: "100",
                                        disabled: _vm.diasabledInput,
                                        readonly: "true",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.formData.requestPoTracingNo,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "requestPoTracingNo",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "formData.requestPoTracingNo"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("RequestSo"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("RequestSo"),
                                      prop: "requestSo",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        maxlength: "64",
                                        disabled: _vm.diasabledInput,
                                        readonly: "true",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.formData.requestSo,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "requestSo",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formData.requestSo"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("RequestSoTracingNo"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("RequestSoTracingNo"),
                                      prop: "requestSoTracingNo",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        maxlength: "100",
                                        disabled: _vm.diasabledInput,
                                        readonly: "true",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.formData.requestSoTracingNo,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "requestSoTracingNo",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "formData.requestSoTracingNo"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("ProcessCode"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("ProcessCode"),
                                      prop: "processCode",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        maxlength: "64",
                                        disabled: _vm.diasabledInput,
                                        readonly: "true",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.formData.processCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "processCode",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formData.processCode"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("FieldValue"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("FieldValue"),
                                      prop: "fieldValue",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        maxlength: "4096",
                                        disabled: _vm.diasabledInput,
                                        readonly: "true",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.formData.fieldValue,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "fieldValue",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formData.fieldValue"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("FileValue"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("FileValue"),
                                      prop: "fileValue",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        maxlength: "4096",
                                        disabled: _vm.diasabledInput,
                                        readonly: "true",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.formData.fileValue,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "fileValue",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formData.fileValue"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("UsedNumber"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("UsedNumber"),
                                      prop: "usedNumber",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        maxlength: "100",
                                        disabled: _vm.diasabledInput,
                                        readonly: "true",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.formData.usedNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "usedNumber",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formData.usedNumber"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("LOSSRATE"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("LOSSRATE"),
                                      prop: "lossRate",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        maxlength: "64",
                                        disabled: _vm.diasabledInput,
                                        readonly: "true",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.formData.lossRate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "lossRate",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formData.lossRate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("FIBER TYPE"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("FIBER TYPE"),
                                      prop: "fiberType",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        maxlength: "100",
                                        disabled: _vm.diasabledInput,
                                        readonly: "true",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.formData.fiberType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "fiberType",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formData.fiberType"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("RATIO"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("RATIO"),
                                      prop: "ratio",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        maxlength: "64",
                                        disabled: _vm.diasabledInput,
                                        readonly: "true",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.formData.ratio,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "ratio",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formData.ratio"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }